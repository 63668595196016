<template>
  <div class='personal-quit'>
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('accountBusiness')}} / {{businessCode=="11"? $t('cancellationClientAccount'):$t('cancellationCompanyAccount')}}</span></template>
    </el-page-header>
    <el-card class="my-card" shadow="never">
      <el-form :inline="true" :model="queryForm" id="query-form">
        <el-form-item :label="$t('account')">
          <el-input v-model="queryForm.cardNo" size="small" :placeholder="$t('inputPhoneOrAccount')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('linkPhone')">
          <el-input v-model="queryForm.phone" size="small" :placeholder="$t('inputPhoneOrAccount')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small" :loading="loading">{{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <div class="basic-information">
        <el-divider v-if="accountFrom" content-position="left">{{$t('basicInfo')}}</el-divider>
        <el-form v-if="accountFrom" :model="accountFrom" class="middle-form" label-position="top">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item :label="$t('customerName')">
                <el-input size="medium" v-model="accountFrom.customerName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('cardNumber')">
                <el-input size="medium" v-model="accountFrom.cardNo" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('accountAmount')">
                <el-input size="medium" v-model="accountFrom.accountAmount" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider v-if="accountFrom" content-position="left">{{$t('confirm')+$t('accountCancellation')}}</el-divider>

        <el-form v-if="accountFrom" :model="cancellationForm" :rules="rules" class="finally-form" ref="cancellationForm" label-position="top">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item prop="serviceChargeAmount">
                <template slot="label">
                  <span style="margin-right:10px">{{$t('serviceCharge')}}</span>
                  <span v-if="serverErrorMsg.length>0" style="font-size:12px;color:red;line-height: normal;">{{$t(serverErrorMsg)}}</span>
                </template>
                <el-input size="medium" v-model="cancellationForm.serviceChargeAmount" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="refundAmount">
                <template slot="label">
                  <span style="margin-right:10px">{{$t('refundAmount')}}</span> &emsp;&emsp;&emsp;
                  <span v-if="serverErrorMsg.length>0" style="font-size:12px;color:red;line-height: normal;">{{$t(serverErrorMsg)}}</span>
                </template>
                <el-input size="medium" v-model="cancellationForm.refundAmount" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('accountCancellation')+$t('reason')" prop="casue">
                <el-select size="medium" v-model="cancellationForm.casue" :placeholder="$t('accountCancellation')+$t('reason')">
                  <el-option :label="$t(`reason${0}`)" :value="0"></el-option>
                  <el-option :label="$t(`reason${1}`)" :value="1"></el-option>
                  <el-option :label="$t(`reason${2}`)" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('messageAuthenticationCode')" prop="smsCode">
                <el-input size="medium" v-model="cancellationForm.smsCode">
                  <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                    <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                    <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('remark')">
                <el-input type="textarea" size="medium" v-model="cancellationForm.remark" :placeholder="$t('remark')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button v-if="serverErrorMsg||!accountFrom" size="medium" type="primary" disabled>{{$t('submit')}}</el-button>
            <el-button v-else size="medium" type="primary" @click="submitForm('cancellationForm')" :loading="loading">{{$t('submit')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </div>
</template>
<script>
import printCredentials from '@/components/printCredentials'
import country from '@/api/country'
import customer from '@/api/customer'
import business from '@/api/business'
import transactionInfo from "@/api/transactionInfo";

export default {
  components: { printCredentials },
  data () {
    return {
      loading: false,
      queryForm: {},
      accountFrom: null,
      format: { value: 'id', label: 'name' },
      countryTree: new Array(),
      cancellationForm: {},

      printVisible: false,
      printParam: null,

      serverErrorMsg: "",
      surplusTime: 0
    };
  },
  computed: {
    rules () {
      return {
        refundAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceCharge: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        casue: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    businessCode () { return this.$route.meta.params.businessCode }
  },
  watch: {},
  methods: {
    query () {
      let _this = this;
      if (!this.queryForm.cardNo && !this.queryForm.phone) {
        this.$message.warning(this.$t('pleaseEnterAccountOrTelephoneNumberQuery'));
        return;
      }
      _this.loading = true;
      customer.getByAccountOrPhone({
        param: _this.queryForm,
        success: res => {
          _this.loading = false;
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg));
          }
          else {
            _this.accountFrom = res.data;
            _this.accountFrom.customerName = _this.accountFrom.name + ' ' + _this.accountFrom.surname;
            _this.getServiceCharge();
          }
        }
      })
    },
    getCountry () {
      let _this = this;
      country.getList({
        success: res => {
          if (res.code != 200) return;
          let tree = res.data.filter(function (t) { return t.parentId == 0 })
          for (let i = 0; i < tree.length; i++) {
            tree[i].children = res.data.filter(function (t) { return t.parentId == tree[i].id });
            let children = tree[i].children;
            for (let l = 0; l < children.length; l++) {
              let c = res.data.filter(function (t) { return t.parentId == children[l].id });
              children[l].children = c.length > 0 ? c : undefined;
            }
          }
          _this.countryTree = tree;
        }
      })
    },
    getSmsVerification () {
      let _this = this;
      transactionInfo.sendVerificationCode({
        param: { phone: this.accountFrom.phoneNo }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }

          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.transferPeopleInfo.phone);
        }
      })
    },

    submitForm (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this[formName].customerId = _this.accountFrom.customerId;
          _this[formName].idCard = _this.accountFrom.idCard;
          _this[formName].businessCode = _this.businessCode;

          _this.loading = true;
          customer.cancellationCustomer({
            param: _this[formName],
            success: res => {
              _this.loading = false;
              if (res.code == 200) {
                _this.$message.success(_this.$t('success'));
                _this.printParam = res.data;
                _this.printVisible = true;

                return true;
              }

              let emsg = "";
              if (res.msg.indexOf('Amount less than') != -1 || res.msg.indexOf('Amount greater than') != -1) {
                let a = res.msg.split(' ');
                emsg = _this.$t('accountBalanceMustBeQqualTo') + a[a.length - 1]
              }
              _this.$message.warning(_this.$t(res.msg) + "! " + emsg);
              return false;
            }
          })
        }
        else return false;
      })
    },

    /**获取手续费,并计算退还金额 */
    getServiceCharge () {
      let _this = this;
      business.serviceChargeCompute({
        param: { businessCode: _this.businessCode, amount: _this.accountFrom.accountAmount },
        success: res => {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            _this.$set(_this, 'serverErrorMsg', res.msg);
            return;
          }
          _this.$set(_this.cancellationForm, 'serviceChargeAmount', res.data);
          _this.$set(_this.cancellationForm, 'refundAmount', _this.accountFrom.accountAmount - res.data);
        }
      })
    }

  },
  mounted () {
    this.getCountry();
  },
}
</script>
<style  lang='less'>
.personal-quit {
  background: @white;
  .el-page-header {
    line-height: 40px;
    margin-left: 15px;
  }
  .my-card {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    .el-card__body {
      height: calc(100% - 30px);
    }
    .el-form-item .el-form-item__content .el-select {
      width: 100%;
    }
    #query-form {
      height: auto;
      .el-form-item {
        margin-bottom: 0;
      }
    }
    .basic-information {
      height: calc(100% - 150px);
      .middle-form {
        height: unset;
      }
      .finally-form {
        height: auto !important;
      }
    }
  }
}
</style>